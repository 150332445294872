<template>
  <div class="page">
    <div class="nav-bar">
      <div class="nav-bar-main">
        <div class="nav-item"
          :class="{ 'active' : current == index }" 
          v-for="(item, index) in categoryList" :key="index" 
          @click="changeType(index)">
          <div class="icon">
            <img :src="item.photo" class="before">
            <img :src="item.photo2" class="after">
          </div>
          <div class="label text-nowrap">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="page-title">
        <div class="title">指导视频</div>
        <div class="desc">解决您产品的使用问题</div>
      </div>
      <div class="list">
        <router-link :to="'/maintenance/detail?id=' + item.id" class="card" v-for="(item, index) in list" :key="index">
          <div class="card-photo">
            <img :src="item.photo" class="poster fit-img">
            <img class="icon-play" src="~@/assets/images/icon/icon_play.svg">
          </div>
          <div class="label text-wrap-2">{{ item.title }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { getCategoryList, getVideoList } from '@/api/maintenance'
  import { Toast } from 'vant'
  
  export default {
    data() {
      return {
        current: 0,
        list: [],
        categoryList: []
      }
    },
    created() {
      this.loadCategoryList()
    },
    methods: {
      loadCategoryList() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getCategoryList().then(res => {
          this.categoryList = res.data.list
          toast.clear()
          this.loadData()
        })
      },
      loadData() {
        const id = this.categoryList[this.current].id
        const params = {
          cate_id: id,
          page: 1,
          limit: 1000
        }
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getVideoList(params).then(res => {
          this.list = res.data.list
          toast.clear()
        })
      },
      changeType(e) {
        this.current = e
        this.loadData()
      },
    },
  }
</script>

<style lang="less" scoped>
.page {
  padding-bottom: calc(4vw + env(safe-area-inset-bottom));
}
.nav-bar {
  width: 100%;
  height: 37.8667vw;
  display: flex;
  align-items: center;
}
.nav-bar-main {
  white-space: nowrap;
  padding: 0 4vw;
  height: 37.8667vw;
  padding-top: 9.3333vw;
}
.nav-item {
  display: inline-flex;
  width: 17.6vw;
  height: 19.2vw;
  flex-direction: column;
  align-items: center;
  padding-top: 3.0667vw;
  border-radius: 0.8vw;
  background: #F6F7F8;
  transition: all .3s ease-in-out
}
.nav-item:last-child {
  margin-right: 4vw;
}
.nav-item +.nav-item {
  margin-left: 3.2vw;
}
.nav-item.active {
  background: #186AFF;
  box-shadow: 0 0.8vw 2.6667vw 0 rgba(24,106,255,0.30);
}
.nav-item.active .icon .before {
  opacity: 0;
}
.nav-item.active .icon .after {
  opacity: 1;
}
.nav-item .icon {
  width: 8vw;
  height: 8vw;
  position: relative;
}
.nav-item .icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 8vw;
  height: 8vw;
  transition: all .3s ease-in-out
}
.nav-item .icon .before {
  z-index: 1;
  opacity: 1;
}
.nav-item .icon .after {
  z-index: 2;
  opacity: 0;
}
.nav-item .label {
  font-size: 2.9333vw;
  color: #A5ADBE;
  text-align: center;
  line-height: 2.9333vw;
  margin-top: 2.1333vw;
  transition: all .3s ease-in-out
}
.nav-item.active .label {
  color: #FFFFFF
}
.page-title {
  display: flex;
  align-items: flex-end;
  margin: 0 4vw;
}
.page-title .title {
  font-weight: bold;
  font-size: 4.5333vw;
  color: #1C1F20;
  line-height: 1;
}
.page-title .desc {
  font-size: 3.2vw;
  color: #9C9DAA;
  line-height: 1;
  margin-left: 2.6667vw;
}
.list {
  margin: 0 4vw;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.card {
  width: 44.4vw;
  margin-top: 8vw;
}
.card-photo {
  width: 44.4vw;
  height: 32vw;
  border-radius: 0.8vw;
  overflow: hidden;
  position: relative;
  background-color: #F6F7F8;
}
.card-photo::after {
  content: '';
  display: block;
  background: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.card-photo .poster {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.8vw;
  z-index: 1;
}
.card-photo .icon-play {
  width: 5.8667vw;
  height: 5.8667vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.card .label {
  font-weight: bold;
  font-size: 3.4667vw;
  color: #2F3437;
  line-height: 5.2vw;
  margin-top: 3.2vw;
}
</style>